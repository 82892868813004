import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  ShowButton,
  SimpleList,
} from 'react-admin';
import MyFilter from './filter';
import MyActions from '../../components/Actions';
import { useMediaQuery } from '@material-ui/core';

const MyList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <List
      {...props}
      filters={<MyFilter />}
      actions={<MyActions />}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filterDefaultValues={{ name: '' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record && record.name}
          secondaryText={record => record && record.email}
          tertiaryText={record =>
            record &&
            record.createdAt &&
            new Date(record.createdAt).toLocaleDateString()
          }
          linkType="show"
        />
      ) : (
        <Datagrid rowClick="show">
          <TextField source="name" sortable={false} />
          <TextField source="email" sortable={false} />
          <DateField source="createdAt" locales="en-GB" />
          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default MyList;
