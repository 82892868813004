import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash';
import LoadingSmall from './LoadingSmall';

function registerListener(event, fn) {
  // eslint-disable-next-line
  if (window.addEventListener) {
    window.addEventListener(event, fn); // eslint-disable-line
  } else {
    window.attachEvent(`on${event}`, fn); // eslint-disable-line
  }
}

function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) && // eslint-disable-line
    rect.left <= (window.innerWidth || document.documentElement.clientWidth) // eslint-disable-line
  );
}

const fadeIn = `
  @keyframes gracefulimage {
    0%   { opacity: 0.25; }
    50%  { opacity: 0.5; }
    100% { opacity: 1; }
  }
`;

class LazyImage extends Component {
  constructor(props) {
    super(props);
    const { retry } = this.props;

    // store a reference to the throttled function
    this.throttledFunction = throttle(this.lazyLoad, 150);

    this.state = {
      loaded: false,
      retryDelay: retry.delay,
      retryCount: 1,
    };
  }

  /*
    Attempts to load an image src passed via props
    and utilises image events to track sccess / failure of the loading
  */
  componentDidMount() {
    const { noLazyLoad } = this.props;
    this.addAnimationStyles();

    // if user wants to lazy load
    if (!noLazyLoad) {
      // check if already within viewport to avoid attaching listeners
      if (isInViewport(this.placeholderImage)) {
        this.loadImage();
      } else {
        registerListener('load', this.throttledFunction);
        registerListener('scroll', this.throttledFunction);
        registerListener('resize', this.throttledFunction);
        registerListener('gestureend', this.throttledFunction); // to detect pinch on mobile devices
      }
    } else {
      this.loadImage();
    }
  }

  /*
    Clear timeout incase retry is still running
    And clear any existing event listeners
  */
  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout); // eslint-disable-line
    }
    this.clearEventListeners();
  }

  /*
    If placeholderImage is currently within the viewport then load the actual image
    and remove all event listeners associated with it
  */
  lazyLoad = () => {
    if (isInViewport(this.placeholderImage)) {
      this.clearEventListeners();
      this.loadImage();
    }
  };

  /*
    Creating a stylesheet to hold the fading animation
  */
  addAnimationStyles = () => {
    const exists = document.head.querySelectorAll('[data-gracefulimage]'); // eslint-disable-line

    if (!exists.length) {
      const styleElement = document.createElement('style'); // eslint-disable-line
      styleElement.setAttribute('data-gracefulimage', 'exists');
      document.head.appendChild(styleElement); // eslint-disable-line
      styleElement.sheet.insertRule(fadeIn, styleElement.sheet.cssRules.length);
    }
  };

  /*
    Attempts to download an image, and tracks its success / failure
  */
  loadImage() {
    const { src } = this.props;
    const image = new Image(); // eslint-disable-line
    image.onload = () => {
      this.setState({ loaded: true });
      if (this.props.onImageLoad) this.props.onImageLoad();
    };
    image.onerror = () => {
      this.handleImageRetries(image);
    };
    image.src = src;
  }

  clearEventListeners() {
    window.removeEventListener('load', this.throttledFunction); // eslint-disable-line
    window.removeEventListener('scroll', this.throttledFunction); // eslint-disable-line
    window.removeEventListener('resize', this.throttledFunction); // eslint-disable-line
    window.removeEventListener('gestureend', this.throttledFunction); // eslint-disable-line
  }

  /*
    Handles the actual re-attempts of loading the image
    following the default / provided retry algorithm
  */
  handleImageRetries(image) {
    const { retry, src } = this.props;
    const { retryCount } = this.state;
    this.setState({ loaded: false }, () => {
      if (retryCount <= retry.count) {
        this.timeout = setTimeout(() => {
          // re-attempt fetching the image
          image.src = src; // eslint-disable-line

          // update count and delay
          this.setState(prevState => {
            let updateDelay;
            if (retry.accumulate === 'multiply') {
              updateDelay = prevState.retryDelay * retry.delay;
            } else if (retry.accumulate === 'add') {
              updateDelay = prevState.retryDelay + retry.delay;
            } else if (retry.accumulate === 'noop') {
              updateDelay = retry.delay;
            } else {
              updateDelay = 'multiply';
            }

            return {
              retryDelay: updateDelay,
              retryCount: prevState.retryCount + 1,
            };
          });
        }, this.state.retryDelay * 1000); // eslint-disable-line
      }
    });
  }

  render() {
    const { loaded } = this.state;
    const { alt, style, src, width, height } = this.props;

    if (!loaded) {
      return (
        // eslint-disable-next-line
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
          ref={ref => (this.placeholderImage = ref)}
        >
          <LoadingSmall />
        </div>
      );
    }

    const styleNew = {
      animationName: 'gracefulimage',
      animationDuration: '0.3s',
      animationIterationCount: 1,
      animationTimingFunction: 'ease-in',
    };

    return (
      <img
        src={src}
        className="loaded"
        width={width}
        height={height}
        style={{
          ...styleNew,
          ...style,
        }}
        alt={alt}
      />
    );
  }
}

LazyImage.defaultProps = {
  width: null,
  height: null,
  alt: '',
  style: {},
  retry: {
    count: 50,
    delay: 0.1,
    accumulate: 'add',
  },
  noRetry: false,
  noLazyLoad: true,
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alt: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  retry: PropTypes.shape({
    count: PropTypes.number,
    delay: PropTypes.number,
    accumulate: PropTypes.string,
  }),
  noRetry: PropTypes.bool, // eslint-disable-line
  noLazyLoad: PropTypes.bool,
};

export default LazyImage;
