import React, { useState } from 'react';
import {
  Mutation,
  useNotify,
  useTranslate,
  useRefresh,
  Confirm,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
  button: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const UndeleteButton = ({ record, resource }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const options = {
    undoable: false,
    onSuccess: ({ data }) => {
      setOpen(false);
      notify('ra.notification.undeleted', 'info', {}, false);
      refresh();
    },
    onFailure: error => notify(`${error.message}`, 'warning', {}, false),
  };

  return (
    <Mutation
      type="DELETE"
      resource={resource}
      payload={{ id: record.id }}
      options={options}
    >
      {action => (
        <>
          <Button
            className={classnames(classes.button, 'actionButton')}
            color="primary"
            size="small"
            onClick={() => setOpen(true)}
          >
            <DeleteIcon className={classes.leftIcon} />
            {translate('ra.action.undelete')}
          </Button>
          <Confirm
            isOpen={open}
            title={`Undelete ${resource}`}
            content={translate('ra.notification.confirmUndelete')}
            onConfirm={() => action()}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </Mutation>
  );
};

export default UndeleteButton;
