import React, { useState } from 'react';
import {
  Mutation,
  useNotify,
  useTranslate,
  useRefresh,
  Confirm,
} from 'react-admin';
import Button from '@material-ui/core/Button';

const UpdateActionButton = ({
  record,
  resource,
  title,
  confirmText,
  updatedText,
  updateObj,
  icon,
}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const options = {
    undoable: false,
    onSuccess: ({ data }) => {
      setOpen(false);
      notify(updatedText || 'ra.notification.updated', 'info', {}, false);
      refresh();
    },
    onFailure: error => notify(`${error.message}`, 'warning', {}, false),
  };

  const payload = {
    id: record && record.id,
    data: { id: record && record.id, ...updateObj },
  };

  const Icon = icon;

  return (
    <Mutation
      type="UPDATE"
      resource={resource}
      payload={payload}
      options={options}
    >
      {action => (
        <>
          <Button
            color="primary"
            size="small"
            onClick={() => setOpen(true)}
            component="a"
            className="actionButton"
          >
            <Icon style={{ marginRight: '6px' }} />
            {title}
          </Button>
          <Confirm
            isOpen={open}
            title={title}
            content={translate(confirmText) || 'Are you sure?'}
            onConfirm={() => action()}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </Mutation>
  );
};

export default UpdateActionButton;
