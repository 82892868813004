import React from "react";
import { Field } from "react-final-form";
import Button from "@material-ui/core/Button";
import CloudinaryUploader from "./CloudinaryUploader";
import LazyImage from "./LazyImage";
import LoadingPercent from "./LoadingPercent";
import DeleteIcon from "@material-ui/icons/Delete";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { apiUrl } from "../config";

const serverApi = apiUrl.replace(/\/graphql-admin$/, "");

const isDev = !!window.location.href.match(/:3000\//);

class CloudinaryImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.uploadInput = React.createRef();

    const isEdit = !!(props.record && (props.record.length || props.record.id));

    let url;

    if (isEdit) {
      const matches = props.source.match(/^(.+?)\[(\d+)\]$/);

      if (!matches) {
        // single
        url = props.formState.values[props.source];
      } else {
        // array
        url = props.formState.values[matches[1]][parseInt(matches[2], 10)];
      }
    }

    this.state = {
      loading: false,
      percent: 0,
      url,
    };

    if (isEdit && url && props.form) {
      this.props.form.change(this.props.source, url);
    }
  }

  reset() {
    if (this.props.form) this.props.form.change(this.props.source, "");

    this.setState({ url: null });
  }

  setImage(url) {
    if (this.props.form) this.props.form.change(this.props.source, url);

    this.setState({ url, loading: false });
  }

  onFinishFileUpload(publicUrl) {
    this.setState({ url: null, loading: true });
    this.setImage(publicUrl);
  }

  devFileUpload() {
    if (!isDev) return;

    this.setState({ url: null, loading: true });

    this.setImage(`https://picsum.photos/200/200?random=${Date.now()}`);
  }

  onFileProgress(percentage) {
    if (percentage === 0) {
      const loadingImages = this.props.formState.values.loadingImages || [];
      this.props.form.change("loadingImages", [
        ...loadingImages,
        this.props.source,
      ]);

      return this.setState({ url: null, loading: true });
    }
    if (percentage === 100) {
      return this.setState({ loading: false });
    }
    this.setState({ percent: percentage });
  }

  onImageLoad() {
    const loadingImages = this.props.formState.values.loadingImages || [];

    this.props.form.change(
      "loadingImages",
      loadingImages.filter((f) => f !== this.props.source)
    );
  }

  render() {
    const {
      disabled,
      label,
      path,
      source,
      isRequired,
      form,
      transformation,
    } = this.props;
    const { loading, url, percent } = this.state;

    if (disabled) return <span />;

    let errorRequired = false;

    if (isRequired && source) {
      const field = form.getFieldState(source);

      if (field && field.submitFailed) {
        errorRequired = true;
      }
    }

    const uploadOptions = {
      path,
      signingUrl: `${serverApi}/sign-image-admin`,
      signingUrlMethod: "POST",
      signingUrlHeaders: { "x-token-admin": localStorage.getItem("token") },
      onFinish: this.onFinishFileUpload.bind(this),
      onProgress: this.onFileProgress.bind(this),
      ref: this.uploadInput,
      id: source + "-fileInput",
      transformation,
    };

    return (
      <React.Fragment>
        <Field
          type="hidden"
          component="input"
          id={source}
          name={source}
          source={source}
        />
        {label && !label.startsWith("resources") && (
          <>
            <span className="MuiFormLabel-root">
              {`${label}${isRequired ? " *" : ""}`}
            </span>
            {errorRequired && (
              <span
                style={{ marginLeft: "12px" }}
                className="MuiFormHelperText-root Mui-error"
              >
                Required
              </span>
            )}
          </>
        )}
        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "12px",
            height: "56px",
          }}
        >
          <>
            <Button
              disabled={!!loading}
              color="primary"
              variant="outlined"
              size="small"
              style={{ marginRight: "12px" }}
              onClick={this.devFileUpload.bind(this)}
            >
              <UploadIcon />
              <label
                htmlFor={`${source}-fileInput${isDev ? "Dev" : ""}`}
                style={{ padding: "3px 10px", display: "inline-block" }}
              >
                {"Upload"}
              </label>
            </Button>
            {url && (
              <Button
                onClick={this.reset.bind(this)}
                disabled={!!loading}
                size="small"
                variant="outlined"
                className="ra-delete-button"
                style={{
                  color: "#f44336",
                  marginRight: "12px",
                }}
              >
                <DeleteIcon />
                <label style={{ padding: "3px 10px", display: "inline-block" }}>
                  Remove
                </label>
              </Button>
            )}
            {loading && <LoadingPercent percentage={percent} />}
            {url && !loading && (
              <div
                style={{
                  width: 50,
                  height: 50,
                  border: "1px solid #ccc",
                  padding: "1px",
                }}
              >
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <LazyImage
                    alt="Preview"
                    src={url}
                    width="50"
                    height="50"
                    onImageLoad={this.onImageLoad.bind(this)}
                  />
                </a>
              </div>
            )}
            <CloudinaryUploader {...uploadOptions} />
          </>
        </div>
      </React.Fragment>
    );
  }
}

export default CloudinaryImageInput;
