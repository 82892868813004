import React from "react";
import {
  TextField,
  DateField,
  Show,
  FunctionField,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  EditButton,
  ListButton,
  DeleteButton,
  ReferenceManyField,
  SimpleList,
  ShowButton,
  Pagination,
  Datagrid,
} from "react-admin";
import Title from "../../components/Title";
import Deleted from "../../components/Deleted";
import DeletedShowInfo from "../../components/DeletedShowInfo";
import { languages } from "../../config";
import UserPhoto from "../../components/UserPhoto";
import UpdateActionButton from "../../components/UpdateActionButton";
import EmailIcon from "@material-ui/icons/MailOutline";
import { useMediaQuery } from "@material-ui/core";

const UserShowActions = ({ basePath, data, resource }) => {
  if (!data || data.deletedAt)
    return (
      <TopToolbar>
        <ListButton basePath={basePath} record={data} resource={resource} />
      </TopToolbar>
    );

  return (
    <TopToolbar>
      <div style={{ float: "left" }}>
        <UpdateActionButton
          record={data}
          resource={resource}
          title="Welcome"
          updateObj={{ action: "sendWelcomeEmail" }}
          icon={EmailIcon}
          updatedText="ra.message.sendWelcomeEmail_done"
          confirmText="ra.message.sendWelcomeEmail_confirm"
        />
      </div>
      <ListButton basePath={basePath} record={data} resource={resource} />
      <EditButton basePath={basePath} record={data} resource={resource} />
      <DeleteButton
        basePath={basePath}
        record={data}
        resource={resource}
        undoable={false}
        redirect={false}
      />
    </TopToolbar>
  );
};

const MyShow = ({ hasShow, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Show
      title={<Title model="User" field="name" />}
      actions={<UserShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="summary">
          <Deleted />
          <DeletedShowInfo record={props.record} />
          <TextField source="id" />
          <FunctionField
            label="Photo"
            render={(record) =>
              record.image ? <UserPhoto photo={record.image} /> : "-"
            }
          />
          <TextField source="name" emptyText="-" />
          <FunctionField
            label="Language"
            render={(record) => {
              if (!record || !record.language) return null;
              const language = languages.find((f) => f.id === record.language);
              return language ? language.name : "-";
            }}
          />
          <TextField source="email" emptyText="-" />
          <DateField
            source="emailVerified"
            locales="en-GB"
            showTime
            emptyText="-"
          />
        </Tab>
        <Tab label="itineraries">
          <ReferenceManyField
            label=""
            reference="Itinerary"
            target="userId"
            pagination={<Pagination />}
            sort={{ field: "createdAt", order: "DESC" }}
            className="mobileList"
          >
            {isSmall ? (
              <SimpleList
                primaryText={(record) =>
                  `${record?.title?.substring(0, 35)}${
                    record?.title?.length > 35 ? "..." : ""
                  }`
                }
                secondaryText={(record) => ""}
                tertiaryText={(record) =>
                  record &&
                  record.createdAt &&
                  new Date(record.createdAt).toLocaleDateString("en-GB")
                }
                linkType="show"
              />
            ) : (
              <Datagrid rowClick="show">
                <TextField source="title" />
                <DateField source="createdAt" locales="en-GB" showTime />
                <ShowButton />
              </Datagrid>
            )}
          </ReferenceManyField>
        </Tab>
        <Tab label="dates">
          <DateField source="createdAt" locales="en-GB" showTime />
          <DateField source="updatedAt" locales="en-GB" showTime />
          <DateField
            source="deletedAt"
            locales="en-GB"
            showTime
            emptyText="-"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default MyShow;
