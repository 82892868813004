import React, { useEffect } from "react";
import UserIcon from "@material-ui/icons/Face";
import ItineraryIcon from "@material-ui/icons/Map";
import TotalCard from "./TotalCard";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Title } from "react-admin";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "12px",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Title title="Perfect Days Admin" />
      <Grid container spacing={1} key={`dashboard-refresh-${props.views}`}>
        <TotalCard col={6} resource="User" title="Users" icon={UserIcon} />
        <TotalCard
          col={6}
          resource="Itinerary"
          title="Itineraries"
          icon={ItineraryIcon}
        />
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({ views: state.admin.ui.viewVersion });

export default connect(mapStateToProps, {})(Dashboard);
