import { createMuiTheme } from "@material-ui/core/styles";

const colors = {
  dark: "#282c2d",
  light: "#E2E8F0",
  medium: "#464D77",
};

const theme = createMuiTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fff" },
    primary: {
      light: colors.medium,
      main: colors.dark,
      dark: `${colors.dark}b3`,
      contrastText: "#fff",
    },
    secondary: {
      light: colors.light,
      main: colors.medium,
      dark: colors.dark,
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    fontFamily: ['"Poppins"', "sans-serif"].join(","),
    fontSize: 14,
  },
});

export default theme;
