import React, { Component } from "react";
import buildGraphQLProvider from "ra-data-graphql-simple";
import { Admin, Resource } from "react-admin";
import authProvider from "./utils/authProvider";
import Loading from "./components/Loading";
import Dashboard from "./components/Dashboard/index";

import AdministratorIcon from "@material-ui/icons/SupervisedUserCircle";
import AdministratorList from "./resources/administrators/list";
import AdministratorEdit from "./resources/administrators/edit";
import AdministratorShow from "./resources/administrators/show";
import AdministratorCreate from "./resources/administrators/create";

import UserIcon from "@material-ui/icons/Face";
import UserList from "./resources/users/list";
import UserEdit from "./resources/users/edit";
import UserShow from "./resources/users/show";
import UserCreate from "./resources/users/create";

import ItineraryIcon from "@material-ui/icons/Map";
import ItineraryList from "./resources/itineraries/list";
import ItineraryEdit from "./resources/itineraries/edit";
import ItineraryShow from "./resources/itineraries/show";
import ItineraryCreate from "./resources/itineraries/create";

import theme from "./utils/theme";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { apiUrl } from "./config";
import englishMessages from "./utils/i18n.en";
import polyglotI18nProvider from "ra-i18n-polyglot";
import MyLayout from "./components/MyLayout";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "en") return englishMessages;
}, localStorage.getItem("lang") || "en");

class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }

  async componentDidMount() {
    const httpLink = createHttpLink({ uri: apiUrl });

    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem("token");

      return {
        headers: {
          ...headers,
          "x-token-admin": token || "",
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache().restore({}),
    });

    buildGraphQLProvider({
      client,
    }).then(
      (dataProvider) => {
        this.setState({ dataProvider });
      },
      (reason) => {
        console.log("Error connecting to graphql:", reason);

        const canReload = localStorage.getItem("token");

        localStorage.removeItem("token");
        localStorage.removeItem("userId");

        if (canReload) window.location.reload();
      }
    );
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <Loading />;
    }

    return (
      <Admin
        title="Perfect Days Admin"
        dataProvider={dataProvider}
        authProvider={authProvider}
        theme={theme}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        dashboard={Dashboard}
        disableTelemetry
      >
        <Resource
          name="User"
          list={UserList}
          edit={UserEdit}
          show={UserShow}
          create={UserCreate}
          icon={UserIcon}
        />
        <Resource
          name="Itinerary"
          list={ItineraryList}
          edit={ItineraryEdit}
          show={ItineraryShow}
          create={ItineraryCreate}
          icon={ItineraryIcon}
        />
        <Resource
          name="Administrator"
          list={AdministratorList}
          edit={AdministratorEdit}
          show={AdministratorShow}
          create={AdministratorCreate}
          icon={AdministratorIcon}
        />
      </Admin>
    );
  }
}

export default App;
