const en = {
  resources: {
    Administrator: {
      name: "Administrator |||| Administrators",
      fields: {
        email: "E-Mail",
        password: "Password",
        createdAt: "Created",
        updatedAt: "Updated",
        deletedAt: "Deleted",
        deleted: "Deleted",
      },
    },
    User: {
      name: "User |||| Users",
      fields: {
        name: "Name",
        email: "E-Mail",
        createdAt: "Created",
        updatedAt: "Updated",
        deletedAt: "Deleted",
        deleted: "Deleted",
      },
    },
    Itinerary: {
      name: "Itinerary |||| Itineraries",
      fields: {
        title: "Title",
        createdAt: "Created",
        updatedAt: "Updated",
        deletedAt: "Deleted",
        deleted: "Deleted",
      },
    },
  },
  ra: {
    action: {
      add_filter: "Filters",
      add: "Add",
      back: "Back",
      bulk_actions: "%{smart_count} selected",
      cancel: "Cancel",
      clear_input_value: "Clean input value",
      clone: "Clone",
      confirm: "Confirm",
      create: "Create",
      delete: "Delete",
      edit: "Edit",
      export: "Export",
      list: "List",
      refresh: "Refresh",
      remove_filter: "Remove filter",
      remove: "Remove",
      save: "Save",
      search: "Search",
      show: "Show",
      sort: "Sort",
      undo: "Undo",
      deleted: "Deleted",
      undelete: "Undelete",
    },
    boolean: {
      true: "Yes",
      false: "No",
    },
    page: {
      delete: "Delete %{name}",
      create: "Create %{name}",
      dashboard: "Dashboard",
      edit: "%{name} #%{id}",
      list: "List %{name}",
      loading: "Loading",
      not_found: "Not found",
      show: "%{name} #%{id}",
      empty: "Empty",
      invite: "",
      error: "Error",
    },
    input: {
      references: {
        all_missing: "References not found.",
        many_missing: "References not found.",
        single_missing: "Reference not found.",
      },
    },
    message: {
      about: "About",
      are_you_sure: "Are you sure?",
      delete_content: "Are you sure you want to delete this item?",
      delete_title: "Delete %{name}",
      invalid_form: "Please check the required fields",
      loading: "Loading...",
      no: "No",
      not_found: "Not found",
      yes: "Yes",
      error: "Error occured",
      details: "Details",
      undelete_title: "Undelete %{name}",
      undelete_content: "Are you sure you want to undelete this item?",
      unsaved_changes:
        "There are unsaved changes. Are you sure you want to leave?",
      sendWelcomeEmail_done: "Welcome email sent",
      sendWelcomeEmail_confirm: "Are you sure you want to send welcome email?",
      copy_clipboard: "Copy to Clipboard",
      copied_clipboard: "Copied to Clipboard",
      close: "Close",
      user_created: "User created",
      user_updated: "User updated",
      administrator_created: "Administrator created",
      administrator_updated: "Administrator updated",
      itinerary_created: "Itinerary created",
      itinerary_updated: "Itinerary updated",
    },
    navigation: {
      no_results: "No results",
      no_more_results: "No more results",
      page_out_of_boundaries: "Page %{page} out of boundaries",
      page_out_from_end: "Out from end",
      page_out_from_begin: "Out from begin",
      page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
      page_rows_per_page: "Rows per page:",
      next: "Next",
      prev: "Prev",
    },
    auth: {
      user_menu: "Profile",
      username: "Email",
      password: "Password",
      sign_in: "Sign In",
      sign_in_error: "Authentication error",
      logout: "Logout",
      auth_check_error: "Please sign in again",
    },
    notification: {
      updated: "Item updated",
      created: "Item created",
      deleted: "Item deleted",
      bad_item: "Invalid item",
      item_doesnt_exist: "Item doesn't exist",
      http_error: "Api error",
      data_provider_error: "Api error",
      canceled: "Canceled",
      logged_out: "Logged out",
      confirmUndelete: "Are you sure you want to undelete this item?",
      undeleted: "Item undeleted",
    },
    validation: {
      required: "Required",
      minLength: "Min. %{min} chars",
      maxLength: "Max. %{max} chars",
      minValue: "Must be %{min} or greater",
      maxValue: "Must be %{max} or smaller",
      number: "Must be a number",
      email: "Must be a valid email address",
      oneOf: "Must be one of the following options: %{options}",
      regex: "Must have this format (regexp): %{pattern}",
    },
  },
  "Error: GraphQL error: InvalidEmailOrPassword": "Invalid Email or Password",
  "GraphQL error: InvalidEmailOrPassword": "Invalid Email or Password",
  "GraphQL error: CantDeleteLastAdmin": "You can't delete the last admin",
  "GraphQL error: CantDeleteSelf": "You can't delete yourself",
  "GraphQL error: EmailAlreadyExists": "Email already exists",
  "GraphQL error: ForeignKeys":
    "Can't delete/undelete this record. Check for child records.",
  "GraphQL error: InternalServerError": "Server error",
  "GraphQL error: NotAdminAuthenticated GraphQL error: NotAdminAuthenticated":
    "Not authenticated",
  "GraphQL error: NotAdminAuthenticated": "Not authenticated",
  "Network error: Response not successful: Received status code 500":
    "Please sign in again",
  "GraphQL error: EmailAlreadyConfirmed": "Email already confirmed",
};

export default en;
