import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  FormDataConsumer,
  email,
  useNotify,
  useRedirect,
} from "react-admin";
import Title from "../../components/Title";
import Deleted from "../../components/Deleted";
import EditActions from "../../components/EditActions";
import { maxLength } from "../../utils/validations";
import { languages } from "../../config";
import WithForm from "../../components/WithForm";
import CloudinaryImageInput from "../../components/CloudinaryImageInput";
import FormToolbar from "../../components/FormToolbar";

const MyEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data: record }) => {
    notify("ra.message.user_updated");
    redirect("show", props.basePath, record.id);
  };
  return (
    <Edit
      {...props}
      undoable={false}
      title={<Title model="User" field="name" />}
      actions={<EditActions />}
      onSuccess={onSuccess}
    >
      <SimpleForm
        redirect="show"
        toolbar={<FormToolbar s3 />}
        submitOnEnter={false}
        variant="outlined"
      >
        <Deleted />
        <TextInput
          source="email"
          type="email"
          validate={[required(), email(), maxLength(128)]}
          options={{ autoComplete: "no" }}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <WithForm>
              {(props) => (
                <CloudinaryImageInput
                  path="frontend/users/"
                  source="image"
                  label="Photo"
                  id="photo"
                  transformation="w_400,c_scale"
                  {...props}
                  {...rest}
                />
              )}
            </WithForm>
          )}
        </FormDataConsumer>
        <TextInput
          source="name"
          validate={[required(), maxLength(128)]}
          options={{ autoComplete: "off" }}
        />
        <SelectInput
          source="language"
          validate={[maxLength(128)]}
          choices={languages}
        />
      </SimpleForm>
    </Edit>
  );
};

export default MyEdit;
