import React from "react";
import { Filter, TextInput, BooleanInput } from "react-admin";

const MyFilter = (props) => {
  return (
    <Filter {...props} variant="outlined">
      <TextInput
        source="title"
        alwaysOn
        options={{ autoComplete: "off" }}
        style={{ marginTop: "32px" }}
      />
      <BooleanInput source="deleted" />
    </Filter>
  );
};

export default MyFilter;
