import React from 'react';

const DeletedShowInfo = ({ record }) => {
  if (record && record.deletedAt) {
    return <span />;
  }

  return null;
};

export default DeletedShowInfo;
