import React from "react";

const Loading = () => (
  <svg
    style={{
      position: "absolute",
      top: "calc(50% - 40px)",
      left: "calc(50% - 40px)",
      display: "block",
      shapeRendering: "auto",
      color: "#464D77",
    }}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    width="80px"
    height="80px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="currentColor"
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
      transform="rotate(209.891 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
);

export default Loading;
