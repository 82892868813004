import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  email,
  useNotify,
  useRedirect,
} from 'react-admin';
import { maxLength, minLength } from '../../utils/validations';
import Title from '../../components/Title';
import Deleted from '../../components/Deleted';
import EditActions from '../../components/EditActions';
import FormToolbar from '../../components/FormToolbar';

const MyEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data: record }) => {
    notify('ra.message.administrator_updated');
    redirect('show', props.basePath, record.id);
  };
  return (
    <Edit
      {...props}
      undoable={false}
      title={<Title model="Administrator" />}
      actions={<EditActions />}
      onSuccess={onSuccess}
    >
      <SimpleForm
        redirect="show"
        toolbar={<FormToolbar />}
        submitOnEnter={false}
        variant="outlined"
      >
        <Deleted />
        <TextInput
          source="name"
          validate={[required(), maxLength(128)]}
          options={{ autoComplete: 'off' }}
        />
        <TextInput
          source="email"
          type="email"
          validate={[required(), email(), maxLength(128)]}
          options={{ autoComplete: 'off' }}
        />
        <TextInput
          source="password"
          type="password"
          validate={[minLength(8), maxLength(128)]}
          autoComplete="new-password"
        />
      </SimpleForm>
    </Edit>
  );
};

export default MyEdit;
