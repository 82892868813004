import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const TextWithImageField = ({
  record,
  size = 32,
  source,
  imageField = "image",
  textFields,
}) => {
  const classes = useStyles();

  if (!record) return null;

  let avatarProps = { src: record[imageField] };
  if (!record[imageField]) {
    avatarProps = {};
  }

  return (
    <div className={classes.root}>
      <Avatar
        {...avatarProps}
        style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
        className={classes.avatar}
      >
        {textFields && textFields.length > 0
          ? record[textFields[0]] && record[textFields[0]].charAt(0)
          : record[source] && record[source].charAt(0)}
      </Avatar>
      {textFields && textFields.length > 1
        ? `${record[textFields[0]]} ${record[textFields[1]]}`
        : record[source] || "-"}
    </div>
  );
};

export default TextWithImageField;
