import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';
import LoadingSmall from './LoadingSmall';
import SaveIcon from '@material-ui/icons/Save';

const FormToolbar = ({ s3, ...props }) => {
  const formState = useFormState();
  let loading = false;

  if (s3) {
    const values = formState.values;
    loading = !!(values.loadingImages && values.loadingImages.length);
  }

  return (
    <Toolbar {...props}>
      {s3 ? (
        <SaveButton
          icon={
            loading ? (
              <LoadingSmall
                style={{ marginRight: '8px' }}
                color="rgba(0, 0, 0, 0.26)"
              />
            ) : (
              <SaveIcon style={{ marginRight: '8px' }} />
            )
          }
          disabled={loading}
          label={loading ? 'Saving Images...' : 'Save'}
        />
      ) : (
        <SaveButton />
      )}
    </Toolbar>
  );
};

export default FormToolbar;
