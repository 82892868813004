import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { useQuery } from 'react-admin';
import Loading from '../LoadingSmall';
import Grid from '@material-ui/core/Grid';

const formatNum = n => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

const styles = {
  card: {
    flex: '1',
    height: '104px',
    color: 'rgba(0, 0, 0, 0.9)',
  },
  icon: {
    float: 'right',
    width: 64,
    height: 64,
    padding: 6,
    color: '#a0aec0',
    opacity: '0.3',
  },
};

const TotalCard = ({
  resource,
  filter = {},
  title,
  col = 3,
  icon,
  iconColor,
}) => {
  const { total, loading, error } = useQuery({
    type: 'GET_LIST',
    resource,
    payload: {
      filter,
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: {
        sortField: 'createdAt',
        sortOrder: 'DESC',
      },
    },
  });

  const Icon = icon;

  if (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    window.location.href = '/#/login';
    return null;
  }

  return (
    <Grid
      item
      xs={6}
      sm={col}
      onClick={e => {
        if (!loading)
          window.location.href = `/#/${resource}${
            filter && filter.state
              ? `?filter=%7B"state"%3A"${filter.state}"%7D`
              : ''
          }`;
      }}
      style={{ cursor: 'pointer' }}
    >
      <Card
        style={{
          ...styles.card,
          backgroundColor: iconColor ? 'rgba(0, 0, 0, 0.04)' : '#fff',
        }}
      >
        {iconColor ? (
          <Icon style={{ ...styles.icon, color: iconColor }} />
        ) : (
          <Icon style={styles.icon} />
        )}
        <CardHeader
          style={styles.header}
          title={total != null && !loading ? formatNum(total) : <Loading />}
          subheader={
            <span style={{ color: 'rgba(0, 0, 0, 0.8)' }}>{title}</span>
          }
        />
      </Card>
    </Grid>
  );
};

export default TotalCard;
