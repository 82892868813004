import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  email,
  useNotify,
  useRedirect,
} from 'react-admin';
import { maxLength, minLength } from '../../utils/validations';
import FormToolbar from '../../components/FormToolbar';

const MyCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data: newRecord }) => {
    notify('ra.message.administrator_created');
    redirect('show', props.basePath, newRecord.id);
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm
        redirect="show"
        submitOnEnter={false}
        toolbar={<FormToolbar />}
        variant="outlined"
      >
        <TextInput
          source="name"
          validate={[required(), maxLength(128)]}
          options={{ autoComplete: 'off' }}
        />
        <TextInput
          source="email"
          type="email"
          validate={[required(), email(), maxLength(128)]}
          options={{ autoComplete: 'no' }}
        />
        <TextInput
          source="password"
          type="password"
          validate={[required(), minLength(8), maxLength(128)]}
          options={{ autoComplete: 'new-password' }}
        />
      </SimpleForm>
    </Create>
  );
};

export default MyCreate;
