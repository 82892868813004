import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  email,
  FormDataConsumer,
  useNotify,
  useRedirect,
} from "react-admin";
import { maxLength } from "../../utils/validations";
import { languages } from "../../config";
import WithForm from "../../components/WithForm";
import CloudinaryImageInput from "../../components/CloudinaryImageInput";
import FormToolbar from "../../components/FormToolbar";

const MyCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data: newRecord }) => {
    notify("ra.message.user_created");
    redirect("show", props.basePath, newRecord.id);
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm
        redirect="show"
        submitOnEnter={false}
        toolbar={<FormToolbar s3 />}
        variant="outlined"
      >
        <TextInput
          source="email"
          type="email"
          validate={[required(), email(), maxLength(128)]}
          options={{ autoComplete: "no" }}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <WithForm>
              {(props) => (
                <CloudinaryImageInput
                  path="frontend/users/"
                  source="image"
                  label="Photo"
                  id="photo"
                  transformation="w_400,c_scale"
                  {...props}
                  {...rest}
                />
              )}
            </WithForm>
          )}
        </FormDataConsumer>
        <TextInput
          source="name"
          validate={[required(), maxLength(128)]}
          options={{ autoComplete: "off" }}
        />
        <SelectInput
          source="language"
          validate={[maxLength(128)]}
          choices={languages}
        />
      </SimpleForm>
    </Create>
  );
};

export default MyCreate;
