import React from "react";
import mime from "mime-types";

const successResponses = [200, 201];

const _getErrorRequestContext = (xhr) => {
  return {
    response: xhr.responseText,
    status: xhr.status,
    statusText: xhr.statusText,
    readyState: xhr.readyState,
  };
};

const createCORSRequest = (method, url) => {
  let xhr = new XMLHttpRequest();

  if (xhr.withCredentials != null) {
    xhr.open(method, url, true);
  } else if (typeof XDomainRequest !== "undefined") {
    xhr = new XDomainRequest(); // eslint-disable-line
    xhr.open(method, url);
  } else {
    xhr = null;
  }
  return xhr;
};

const CloudinaryUploader = React.forwardRef(
  (
    {
      id,
      path,
      signingUrl,
      signingUrlMethod,
      signingUrlHeaders,
      onFinish,
      onProgress,
      transformation,
    },
    ref
  ) => {
    const executeOnSignedUrl = (file, callback) => {
      const fileName = file.name.replace(/[^\w\d_\-\.]+/gi, ""); // eslint-disable-line

      const queryString =
        "?objectName=" +
        fileName +
        "&contentType=" +
        encodeURIComponent(file.type || mime.lookup(file.name)) +
        "&path=" +
        encodeURIComponent(path) +
        "&transformation=" +
        transformation;

      const xhr = createCORSRequest(signingUrlMethod, signingUrl + queryString);

      if (signingUrlHeaders) {
        Object.keys(signingUrlHeaders).forEach(function (key) {
          xhr.setRequestHeader(key, signingUrlHeaders[key]);
        });
      }

      xhr.overrideMimeType &&
        xhr.overrideMimeType("text/plain; charset=x-user-defined");

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && successResponses.indexOf(xhr.status) >= 0) {
          let result;

          try {
            result = JSON.parse(xhr.responseText);
          } catch (error) {
            onError(
              "Invalid response from server",
              file,
              _getErrorRequestContext(xhr)
            );
            return false;
          }

          return callback(file, result);
        } else if (
          xhr.readyState === 4 &&
          successResponses.indexOf(xhr.status) < 0
        ) {
          return onError(
            "Could not contact request signing server. Status = " + xhr.status,
            file,
            _getErrorRequestContext(xhr)
          );
        }
      };
      return xhr.send();
    };

    const uploadFile = (e) => {
      const file = e?.target?.files[0];

      onProgress(0);

      executeOnSignedUrl(file, cloudinaryUpload, transformation);
    };

    const cloudinaryUpload = (file, signResult) => {
      const xhr = createCORSRequest("POST", signResult?.signedUrl?.url);

      if (!xhr) {
        onError("CORS not supported", file, {});
      } else {
        xhr.onload = function () {
          if (successResponses.indexOf(xhr.status) >= 0) {
            const respJson = JSON.parse(xhr.responseText);
            onProgress(100);
            return onFinish(respJson.secure_url);
          } else {
            return onError(
              "Upload error: " + xhr.status,
              file,
              _getErrorRequestContext(xhr)
            );
          }
        };

        xhr.onerror = function () {
          return onError("XHR error", file, _getErrorRequestContext(xhr));
        };

        xhr.upload.onprogress = function (e) {
          let percentLoaded;
          if (e.lengthComputable) {
            percentLoaded = Math.round((e.loaded / e.total) * 100);
            return onProgress(percentLoaded);
          }
        };
      }

      const formData = new FormData();

      formData.append("file", file);
      Object.keys(signResult?.signedUrl?.args).forEach((arg) =>
        formData.append(arg, signResult?.signedUrl?.args[arg])
      );

      return xhr.send(formData);
    };

    const onError = (error, file, extra) => {
      console.log(error, file, extra);
    };

    return (
      <input
        type="file"
        ref={ref}
        className="fileInput"
        id={id}
        onChange={uploadFile}
      />
    );
  }
);

export default CloudinaryUploader;
