import React from "react";
import { TopToolbar, CreateButton } from "react-admin";

/* this is to remove the export button */
const Actions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  permissions,
  showCreate,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {showCreate !== false && <CreateButton basePath={basePath} />}
  </TopToolbar>
);

export default Actions;
