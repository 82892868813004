import React from "react";
import {
  TextField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  FunctionField,
} from "react-admin";
import Title from "../../components/Title";
import Deleted from "../../components/Deleted";
import DeletedShowInfo from "../../components/DeletedShowInfo";
import ShowActions from "../../components/ShowActions";

const MyShow = ({ hasShow, ...props }) => {
  return (
    <Show
      title={<Title model="Itinerary" field="title" />}
      actions={<ShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="summary">
          <Deleted />
          <DeletedShowInfo record={props.record} />
          <TextField source="id" />
          <ReferenceField
            label="User"
            source="userId"
            reference="User"
            link="show"
          >
            <FunctionField
              label="Name"
              render={(record) => record?.name || record?.email}
            />
          </ReferenceField>
          <TextField source="title" />
        </Tab>
        <Tab label="dates">
          <DateField source="createdAt" locales="en-GB" showTime />
          <DateField source="updatedAt" locales="en-GB" showTime />
          <DateField
            source="deletedAt"
            locales="en-GB"
            showTime
            emptyText="-"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default MyShow;
