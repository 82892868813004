import React from 'react';
import { Layout, AppBar } from 'react-admin';
import MyUserMenu from './MyUserMenu';

const MyAppBar = props => {
  return <AppBar {...props} userMenu={<MyUserMenu />} />;
};

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

export default MyLayout;
