import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const ColorCircularProgress = withStyles({
  root: {
    color: "#464D77",
  },
})(CircularProgress);

const LoadingPercent = ({ percentage }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ColorCircularProgress
        variant="determinate"
        value={percentage}
        size={32}
        thickness={4}
      />
      <div className="MuiFormLabel-root" style={{ paddingLeft: "12px" }}>
        {percentage}%
      </div>
    </div>
  );
};

export default LoadingPercent;
