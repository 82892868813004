import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  EditButton,
  DateField,
  ShowButton,
  TextField,
  SimpleList,
} from "react-admin";
import MyFilter from "./filter";
import MyActions from "../../components/Actions";
import TextWithImageField from "../../components/Field/TextWithImageField";
import { useMediaQuery } from "@material-ui/core";

const WithProps = ({ children, ...props }) => {
  if (children) return children(props);
  return <span />;
};

const MyList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <List
      {...props}
      filters={<MyFilter />}
      actions={<MyActions />}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{ name: "" }}
    >
      {isSmall ? (
        <SimpleList
          className="usersMobileList"
          primaryText={(record) => (
            <TextWithImageField
              source="name"
              record={record}
              textFields={["name"]}
              imageField="image"
            />
          )}
          secondaryText={(record) => {
            if (!record || !record.language) return "";
            return ""; // record.language;
          }}
          tertiaryText={(record) =>
            record &&
            record.createdAt &&
            new Date(record.createdAt).toLocaleDateString("en-GB")
          }
          linkType="show"
        />
      ) : (
        <Datagrid rowClick="show">
          <TextWithImageField
            source="name"
            textFields={["name"]}
            imageField="image"
            sortable={false}
          />
          <TextField sortable={false} source="email" />
          <DateField source="createdAt" locales="en-GB" />
          <ShowButton />
          <WithProps>
            {({ record, ...props }) =>
              record &&
              !record.deletedAt && <EditButton record={record} {...props} />
            }
          </WithProps>
        </Datagrid>
      )}
    </List>
  );
};

export default MyList;
