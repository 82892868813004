import React from "react";
import { UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import { version } from "../config";

const MyUserMenu = (props) => {
  const userId = localStorage.getItem("userId");

  return (
    <UserMenu label="My Profile" {...props}>
      <div
        style={{
          textAlign: "center",
          color: "#aaa",
          fontSize: "12px",
          padding: "6px",
        }}
      >
        Version {version}
      </div>
      <MenuItemLink
        to={`/Administrator/${userId}/show`}
        primaryText="My profile"
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  );
};

export default MyUserMenu;
