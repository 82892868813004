import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';

const Title = ({ model, record, field, ...props }) => {
  const translate = useTranslate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <span>
      {translate(`resources.${model}.name`, 1)}
      {record ? ` "${record[field || 'name']}"` : ''}
    </span>
  );
};

export default Title;
