import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { maxLength } from "../../utils/validations";
import FormToolbar from "../../components/FormToolbar";

const MyCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data: newRecord }) => {
    notify("ra.message.itinerary_created");
    redirect("show", props.basePath, newRecord.id);
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm
        redirect="show"
        submitOnEnter={false}
        toolbar={<FormToolbar />}
        variant="outlined"
      >
        <ReferenceInput label="User" source="userId" reference="User">
          <SelectInput optionText={(record) => record?.name} />
        </ReferenceInput>
        <TextInput
          source="title"
          validate={[required(), maxLength(255)]}
          options={{ autoComplete: "off" }}
        />
      </SimpleForm>
    </Create>
  );
};

export default MyCreate;
