import React from "react";
import { useTranslate } from "react-admin";

const Deleted = ({ record }) => {
  const translate = useTranslate();

  if (!record || !record.deletedAt) return null;

  return (
    <span style={{ color: "#f44336" }}>{translate("ra.action.deleted")}</span>
  );
};

export default Deleted;
