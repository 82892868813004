import React from 'react';
import { TopToolbar, ShowButton, ListButton } from 'react-admin';

const EditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} resource={resource} />
    <ShowButton basePath={basePath} record={data} resource={resource} />
  </TopToolbar>
);

export default EditActions;
