import { getCodeList } from "country-list";

const development =
  window.location.hostname === "admin.peer.test" ||
  window.location.hostname === "localhost";
const staging = window.location.hostname === "admin2.perfectdays.in";
const production = window.location.hostname === "admin.perfectdays.in";

let env = "";

if (development) env = "development";
if (staging) env = "staging";
if (production) env = "production";

const apiUrls = {
  development: "http://api.peer.test:4004/api/graphql-admin",
  staging: "https://fastapi2.perfectdays.in/api/graphql-admin",
  production: "https://fastapi.perfectdays.in/api/graphql-admin",
};

const isoCountries = getCodeList();
export const countries = Object.keys(isoCountries)
  .map((c) => ({
    id: c,
    name: isoCountries[c],
  }))
  .sort((a, b) => (a.name > b.name ? 1 : -1));

export const languages = [
  { id: "en", name: "English" },
  { id: "pt", name: "Portuguese" },
];

export const apiUrl = apiUrls[env];

export const version = "0.9.1";
