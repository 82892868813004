import React from "react";
import Avatar from "@material-ui/core/Avatar";

const UserPhoto = ({ photo }) => (
  <a href={photo} target="_blank" rel="noopener noreferrer">
    <Avatar
      style={{ width: "80px", height: "80px" }}
      alt="user photo"
      src={photo}
    />
  </a>
);

export default UserPhoto;
