import React from 'react';
import { Filter, TextInput, BooleanInput } from 'react-admin';

const MyFilter = props => {
  return (
    <Filter {...props} variant="outlined">
      <TextInput
        source="name"
        alwaysOn
        options={{ autoComplete: 'off' }}
        style={{ marginTop: '32px' }}
      />
      <TextInput source="email" alwaysOn options={{ autoComplete: 'off' }} />
      <BooleanInput source="deleted" />
    </Filter>
  );
};

export default MyFilter;
