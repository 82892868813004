import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  EditButton,
  DateField,
  ShowButton,
  TextField,
  SimpleList,
  ReferenceField,
  FunctionField,
  TopToolbar,
} from "react-admin";
import MyFilter from "./filter";
// import MyActions from "../../components/Actions";
import { useMediaQuery } from "@material-ui/core";

/* this is to remove the export button */
const MyActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
  </TopToolbar>
);

const WithProps = ({ children, ...props }) => {
  if (children) return children(props);
  return <span />;
};

const MyList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <List
      {...props}
      filters={<MyFilter />}
      actions={<MyActions />}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{ title: "" }}
    >
      {isSmall ? (
        <SimpleList
          className="usersMobileList"
          primaryText={(record) =>
            `${record?.title?.substring(0, 35)}${
              record?.title?.length > 35 ? "..." : ""
            }`
          }
          secondaryText={(record) => ""}
          tertiaryText={(record) =>
            record &&
            record.createdAt &&
            new Date(record.createdAt).toLocaleDateString("en-GB")
          }
          linkType="show"
        />
      ) : (
        <Datagrid rowClick="show">
          <TextField sortable={false} source="title" />
          <ReferenceField label="User" source="userId" reference="User" link="">
            <FunctionField
              label="Name"
              render={(record) => record?.name || record?.email}
            />
          </ReferenceField>
          <DateField source="createdAt" locales="en-GB" />
          <ShowButton />
          <WithProps>
            {({ record, ...props }) =>
              record &&
              !record.deletedAt && <EditButton record={record} {...props} />
            }
          </WithProps>
        </Datagrid>
      )}
    </List>
  );
};

export default MyList;
