import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { apiUrl } from "../config";

const SIGNIN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      userId
    }
  }
`;

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: createHttpLink({ uri: apiUrl }),
      });

      const result = await client.mutate({
        mutation: SIGNIN,
        variables: { email: username, password },
      });

      if (!result) throw new Error("AuthError");

      const { token, userId } = result.data.signIn;

      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
    } catch (err) {
      throw new Error(err);
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    return Promise.resolve();
  },
  checkError: (error) => {
    if (!error) return Promise.resolve();
    if (
      (error.extensions && error.extensions.code === "UNAUTHENTICATED") ||
      (error.message && error.message.match(/Received status code 500/)) ||
      (error.message && error.message.match(/NotAdminAuthenticated/))
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");

      if (window.location.hash !== "#/login") {
        window.location.href = "/#/login";
      }
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    if (localStorage.getItem("token") && localStorage.getItem("userId"))
      return Promise.resolve();
    localStorage.removeItem("token");
    localStorage.removeItem("userId");

    return Promise.reject();
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};

export default authProvider;
