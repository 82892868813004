import React from "react";
import {
  TextField,
  DateField,
  EmailField,
  SimpleShowLayout,
  Show,
} from "react-admin";
import Title from "../../components/Title";
import Deleted from "../../components/Deleted";
import DeletedShowInfo from "../../components/DeletedShowInfo";
import ShowActions from "../../components/ShowActions";

const MyShow = ({ hasShow, ...props }) => {
  return (
    <Show
      title={<Title model="Administrator" />}
      actions={<ShowActions />}
      {...props}
    >
      <SimpleShowLayout>
        <Deleted />
        <DeletedShowInfo record={props.record} />
        <TextField source="id" />
        <TextField source="name" />
        <EmailField source="email" />
        <DateField source="createdAt" locales="en-GB" showTime />
        <DateField source="updatedAt" locales="en-GB" showTime />
        <DateField source="deletedAt" locales="en-GB" showTime emptyText="-" />
      </SimpleShowLayout>
    </Show>
  );
};

export default MyShow;
