import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  useNotify,
  useRedirect,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import Title from "../../components/Title";
import Deleted from "../../components/Deleted";
import EditActions from "../../components/EditActions";
import { maxLength } from "../../utils/validations";
import FormToolbar from "../../components/FormToolbar";

const MyEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data: record }) => {
    notify("ra.message.itinerary_updated");
    redirect("show", props.basePath, record.id);
  };
  return (
    <Edit
      {...props}
      undoable={false}
      title={<Title model="Itinerary" field="title" />}
      actions={<EditActions />}
      onSuccess={onSuccess}
    >
      <SimpleForm
        redirect="show"
        toolbar={<FormToolbar />}
        submitOnEnter={false}
        variant="outlined"
      >
        <Deleted />
        <ReferenceInput label="User" source="userId" reference="User">
          <SelectInput optionText={(record) => record?.name || record?.email} />
        </ReferenceInput>
        <TextInput
          source="title"
          validate={[required(), maxLength(255)]}
          options={{ autoComplete: "off" }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default MyEdit;
