import React from 'react';
import { TopToolbar, EditButton, ListButton, DeleteButton } from 'react-admin';
import UndeleteButton from './UndeleteButton';

const ShowActions = ({ basePath, data, resource, hideEdit, hideDelete }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} resource={resource} />
    {!hideEdit && (
      <EditButton basePath={basePath} record={data} resource={resource} />
    )}
    {!hideDelete && data && !data.deletedAt && (
      <DeleteButton
        basePath={basePath}
        record={data}
        resource={resource}
        undoable={false}
        redirect={false}
      />
    )}
    {!hideDelete && data && data.deletedAt != null && (
      <UndeleteButton record={data} resource={resource} />
    )}
  </TopToolbar>
);

export default ShowActions;
