const validatePhone = phone => {
  const re = /^\+\d{12}$/;
  return re.test(phone.toString());
};

module.exports = {
  maxLength: (max, message = 'Too long') => value =>
    value && value.length > max ? `${message} (max ${max})` : undefined,
  minLength: (min, message = 'Too short') => value =>
    value && value.length < min ? `${message} (min ${min})` : undefined,
  isInteger: (message = 'Not a integer') => value =>
    value && !value.toString().match(/^\d+$/) ? message : undefined,
  isPhone: (message = 'Invalid format (ex: +333991234567)') => value =>
    value && !validatePhone(value) ? message : undefined,
  isFloat: (message = 'Not a float') => value =>
    value && !value.toString().match(/^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/)
      ? message
      : undefined,
};
